import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: {
        admin:null
    },
    adminMode:true,
    itemExcluir:{
      nome:'',
      id:'',
      colecao:''
    },
    popup:''
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setPopup(state,popup) {
        state.popup = popup
    },
    setItemExcluir(state,itemExcluir) {
        state.itemExcluir = itemExcluir
    },
    toggleAdminMode(state,adminMode) {
        state.adminMode = !adminMode
    } 
  },
  actions: {
    async fetchUser({ commit }) {
      try {
        const response = await axios.get('https://profloto-backend.vercel.app/users/me', { headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    } });
        commit('setUser', response.data);
      } catch (error) {
        console.log(error);
      }
    },
  },
});

export default store;