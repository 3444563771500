
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import LottieVuePlayer from '@lottiefiles/vue-lottie-player'
import Toasted from 'vue-toasted'

import './style.css'

Vue.use(Toasted, {
    duration: 3000,
    position: 'top-right',
})

Vue.use(LottieVuePlayer)
Vue.config.productionTip = false
Vue.prototype.$bus = new Vue();


new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')