import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const routes = [
  { path: '/', name: 'Inicio', component: () => import('@/views/inicio.vue') },
  { path: '/clientes', name: 'Clientes', component: () => import('@/views/clientes.vue'), meta: { requiresAdmin: true } },
  { path: '/gerenciar-boloes', name: 'GerenciarBoloes', component: () => import('@/views/gerenciar-boloes.vue'), meta: { requiresAdmin: true } },
  { path: '/gerenciar-promocoes', name: 'GerenciarPromocoes', component: () => import('@/views/gerenciar-promocoes.vue'), meta: { requiresAdmin: true } },
  { path: '/login', name: 'Login', component: () => import('@/views/login.vue') },
  { path: '/palpites', name: 'Palpites', component: () => import('@/views/palpites.vue') },
  { path: '/perfil', name: 'Perfil', component: () => import('@/views/perfil.vue') },
  { path: '/trocar-senha', name: 'RedefinirSenha', component: () => import('@/views/trocar-senha.vue') },
]

const router = new Router({ routes, mode:'history' })

router.beforeEach(async (to, from, next) => {
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
  let user = null;
  try {
    await store.dispatch('fetchUser');
    user = store.state.user;
  } catch (error) {
    console.log(error);
  }

  if (!user || (user && !user.ativo)) {
    if (to.name !== 'Login' && to.name !== 'RedefinirSenha') {
      return next({ name: 'Login' });
    }
  } else if (requiresAdmin && !user.admin ) {
    return next({ name: 'Inicio' });

  } else if(user) {
    if(to.name === 'Login' || to.name === 'RedefinirSenha') {
      return next({ name: 'Inicio' });
    }
  }

  window.scrollTo(0, 0);

  
  next();
});


export default router;